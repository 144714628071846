<template>
  <div class="shipments-page">
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      :data="magicLinks"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Description')}}</shipblu-th>
        <shipblu-th>{{$t('Expiry Date')}}</shipblu-th>
        <shipblu-th>{{$t('Max Usage')}}</shipblu-th>
        <shipblu-th>{{$t('Current Usage')}}</shipblu-th>
        <shipblu-th>{{$t('Enabled')}}</shipblu-th>
        <shipblu-th>{{$t('Copy')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].description">
            {{ data[indextr].description }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].expiryDate">
            {{ data[indextr].expiry_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].maxUsage">
            {{ data[indextr].max_usage }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].currentUsage">
            {{ data[indextr].current_usage }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].enabled">
            <vs-switch :disabled="merchantStatus === 'on_hold'" @click="enableMagicLink(data[indextr])" v-model="data[indextr].is_enabled" class="mt-2"/>
          </shipblu-td>
          
          <shipblu-td>
            <span class="link" @click="copied(data[indextr])">{{$t('Copy link')}}</span>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import common  from '@/assets/utils/common'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['magicLinkModal', 'merchantStatus'],
  data () {
    return {
      merchant: JSON.parse(localStorage.getItem('merchant')),
      userMerchant: JSON.parse(localStorage.getItem('userMerchant')),
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      magicLinks: [],
      selected: [],
      magicLink: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadMagicLinks()
    },
    magicLinkModal (val) {
      if (!val) {
        this.loadMagicLinks()
      }
    }
  },
  methods: {
    enableMagicLink (data) {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/magic-link/${data.id}/`, 'patch', {is_enabled: !data.is_enabled}, true,
        () => {
          this.loadMagicLinks()
        }
      )
    },
    loadMagicLinks () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/merchants/${this.merchantID}/magic-link/${query}`, 'get', null, true,
        (response) => {
          this.magicLinks = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    async copied (data) {
      try {
        const link = `${process.env.VUE_APP_MAGIC_LINK}/${this.$i18n.locale}/${this.merchant.name.replace(' ', '%20')}/${data.hash}?order_ref=${data.description ? data.description : ''}`
        await navigator.clipboard.writeText(link)
        this.$vs.notify({
          text: i18nData[this.$i18n.locale]['copied to clipboard!'],
          color:'dark',
          position:'bottom-center'
        })
      } catch ($e) {
        this.$vs.notify({
          text:'Cannot copy!',
          color:'dark',
          position:'bottom-center'
        })
      }
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantID = user.id
      this.loadMagicLinks()
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.link {
  text-decoration: underline;
  color: #1c5bfe
}
</style>
