<template>
  <div class="shipments-page">
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      orders
      :data="messages"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Message')}}</shipblu-th>
        <shipblu-th>{{$t('Message Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].description">
            {{ data[indextr].phone }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].expiryDate">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].maxUsage">
            {{ data[indextr].body }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import common from '../../assets/utils/common.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['magicLinkModal', 'bulkModal'],
  data () {
    return {
      merchant: JSON.parse(localStorage.getItem('merchant')),
      userMerchant: JSON.parse(localStorage.getItem('userMerchant')),
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      messages: [],
      selected: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadOutgoingMessages()
    },
    magicLinkModal (val) {
      if (!val) {
        this.loadOutgoingMessages()
      }
    },
    bulkModal (val) {
      if (!val) {
        this.loadOutgoingMessages()
      }
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadOutgoingMessages () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/magic/sms/${query}`, 'get', null, true,
        (response) => {
          this.messages = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadOutgoingMessages()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.link {
  text-decoration: underline;
  color: #1c5bfe
}
</style>
