<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="magicLinkModal"
    :title="type === 'magic' ? $t('Generate Magic Link') : $t('Send Individual Message')"
    :buttons-hidden="true" autocomplete="nofill">
      <div class="vx-row mb-32" v-if="type === 'magic'">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|numeric|max_value:100'" name="max usage" :label-placeholder="$t('Max Usage') + '*'" v-model="magicLink.max_usage" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('max usage')">{{ errors.first('max usage') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <datepicker v-validate="'required'" :disabledDates="disabledDates" name="expiry date" :placeholder="$t('Expiry Date') + '*'" v-model="magicLink.expiry_date" class="mt-8 w-full"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('expiry date')">{{ errors.first('expiry date') }}</span>
        </div>
        <div class="vx-col w-full">
          <vs-input v-validate="'required'" name="description" :label-placeholder="$t('Description') + '*'" v-model="magicLink.description" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>
      </div>
      <div class="vx-row" v-else>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="full name" :label-placeholder="$t('Full Name') + '*'" v-model="magicLink.name" class="my-4 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="phone number" :placeholder="$t('Phone Number') + '*'" v-model="magicLink.phone" class="my-4 w-full" />
          <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="generateMagicLink" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
  </shipblu-prompt>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['magicLinkModal', 'magicLink', 'type'],
  data () {
    return {
      disabledDates: {
        to: new Date(),
        from: new Date(new Date().setDate(new Date().getDate() + 10))
      },
      image: '',
      file_key: ''
    }
  },
  components: {
    Datepicker,
    ShipbluPrompt
  },
  methods: {
    generateMagicLink () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('generateMagicLink')
        }
      })
    },
    closeModal () {
      this.$emit('magicLinkModal', false)
      this.$emit('loadBankDeposits')
    }
  }
}
</script>