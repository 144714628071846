<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeShipmentsModal"
    :active.sync="bulkModal"
    :title="$t('Upload Multiple SMS')"
    :buttons-hidden="true">
    <div class="text-center mt-5">
      <vs-button><a :href="file_URl" class="text-white">{{$t('Download Template')}}</a></vs-button>
    </div>
    <div class="excel-import">
      <input type="file" ref="fileInput" class="hidden" accept=".xlsx, .xls" @change="handleClick">
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="w-1/2 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
        <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
        <span>{{$t('Drop Excel File or')}} </span>
        <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
      </div>
    </div>
  </shipblu-prompt>
</template>

<script>
import i18nData from '../../../i18n/i18nData'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['bulkModal'],
  data () {
    return {
      file_URl: 'https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/SMS-Template.xlsx',
      file: ''
    }
  },
  methods: {
    closeShipmentsModal () {
      this.$emit('bulkModal', false)
      this.file = false
    },
    uploadFile (file) {
      if (!this.isExcel(file)) {
        this.$vs.notify({
          color: 'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          position: 'top-center'
        })
        return false
      }
      this.$refs['fileInput'].value = null // fix can't select the same excel
      this.file = true
      if (!this.isOnline) this.$emit('bulkModal', false)
      this.$emit('sendBulkSMS', file)
    },
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.uploadFile(rawFile)
    },
    isExcel (file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>